import {
  GET_TAGS_LIST_REQUEST,
  GET_TAGS_LIST_SUCCESS,
  GET_TAGS_LIST_FAILURE,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  DELETE_TAG_FROM_DOMAIN_REQUEST,
  DELETE_TAG_FROM_DOMAIN_SUCCESS,
  DELETE_TAG_FROM_DOMAIN_FAILURE,
  GET_DOMAIN_TAGS_REQUEST,
  GET_DOMAIN_TAGS_SUCCESS,
  GET_DOMAIN_TAGS_FAILURE,
  ADD_DOMAIN_TAGS_REQUEST,
  ADD_DOMAIN_TAGS_SUCCESS,
  ADD_DOMAIN_TAGS_FAILURE,
  ADD_TAG_REQUEST,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAILURE,
  EDIT_TAG_REQUEST,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILURE,
} from 'actions/constants';
import initialState from './initialState';

export default function tagsReducer(state = initialState,
  action) {
  switch (action.type) {
    case GET_TAGS_LIST_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_TAGS_LIST_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: action.data,
      };

    case GET_TAGS_LIST_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        data: {},
      };

    case DELETE_TAG_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        // data: action.data,
      };

    case DELETE_TAG_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        // data: {},
      };

    case DELETE_TAG_FROM_DOMAIN_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case DELETE_TAG_FROM_DOMAIN_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        // data: action.data,
      };

    case DELETE_TAG_FROM_DOMAIN_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        // data: {},
      };

    case GET_DOMAIN_TAGS_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_DOMAIN_TAGS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        // data: action.data,
      };

    case GET_DOMAIN_TAGS_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        // data: {},
      };

    case ADD_DOMAIN_TAGS_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case ADD_DOMAIN_TAGS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        // data: action.data,
      };

    case ADD_DOMAIN_TAGS_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        // data: {},
      };


    case ADD_TAG_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case ADD_TAG_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        // data: action.data,
      };

    case ADD_TAG_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        // data: {},
      };

    case EDIT_TAG_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case EDIT_TAG_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        // data: action.data,
      };

    case EDIT_TAG_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        // data: {},
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
