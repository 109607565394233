import React, { Suspense, lazy } from 'react';
import {
  Route, Switch, withRouter,
} from 'react-router-dom';
import Fallback from 'components/Fallback';
import { PropTypes } from 'prop-types';

const DomainsList = lazy(() => import('components/DomainsList'));
const DomainsRightPanel = lazy(() => import('components/DomainsRightPanel'));

const Domains = (props) => (
  <div className="columns__container">
    <Suspense fallback={<Fallback title="Loading Domains" />}>
      <Switch>
        <Route
          path="/:path(domains|302s)/:domain?"
          render={() => (
            <DomainsList
              defaultFilters={props.defaultFilters}
            />
          )}
        />
      </Switch>
      <Switch>
        <Route
          path="/:path(domains|302s)/:domain"
          render={({ match }) => <DomainsRightPanel key={match.params.domain} />}
        />
      </Switch>
    </Suspense>
  </div>
);

Domains.defaultProps = {
  defaultFilters: [],
};

Domains.propTypes = {
  defaultFilters: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withRouter(Domains);
