import React, { Suspense, lazy } from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import Fallback from 'components/Fallback';

const LocationsList = lazy(() => import('components/LocationsList'));
const DomainsList = lazy(() => import('components/DomainsList'));
const LocationsRightPanel = lazy(() => import('components/LocationsRightPanel'));
const DomainsRightPanel = lazy(() => import('components/DomainsRightPanel'));

const Locations = () => (
  <div className="columns__container">
    <Suspense fallback={<Fallback title="Loading Locations" />}>
      <Switch>
        <Route
          exact
          path="/locations"
          render={() => <LocationsList />}
        />
        <Route
          path="/locations/:city/:state/:domain?"
          render={() => <DomainsList />}
        />
      </Switch>
      <Switch>
        <Route
          exact
          path="/locations/:city/:state?"
          render={() => <LocationsRightPanel />}
        />
        <Route
          exact
          path="/locations/:city/:state/:domain?"
          render={({ match }) => (
            <DomainsRightPanel
              key={match.params.domain}
            />
          )}
        />
      </Switch>
    </Suspense>
  </div>
);

export default Locations;
