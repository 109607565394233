import React, { useState } from 'react';
// import StoreIcon from '@material-ui/icons/Store';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WebIcon from '@material-ui/icons/Web';
import WarningIcon from '@material-ui/icons/Warning';
import BlockIcon from '@material-ui/icons/Block';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {
  NavLink,
} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import BulkDomainsOverlay from 'components/BulkDomainsOverlay/index';
import Button from '@material-ui/core/Button';
import styles from './core.module.scss';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#B2BECB',
    color: '#FFF',
    boxShadow: 'none',
    fontSize: 12,
    padding: 12,
  },
}))(Tooltip);

function Navigation() {
  const [hidden, setHidden] = useState(true);
  return (
    <>
      {hidden ? null : (
        <BulkDomainsOverlay
          onClose={() => setHidden(true)}
        />
      )}
      <nav className={styles.nav__container}>
        <NavLink to="/locations">
          <LightTooltip title="Locations" placement="right">
            <LocationOnIcon color="primary" />
          </LightTooltip>
        </NavLink>
        <NavLink to="/domains">
          <LightTooltip title="Domains" placement="right">
            <WebIcon color="primary" />
          </LightTooltip>
        </NavLink>
        <NavLink to="/302s">
          <LightTooltip title="Hot Leads" placement="right">
            <WarningIcon color="primary" />
          </LightTooltip>
        </NavLink>
        <NavLink to="/blacklist">
          <LightTooltip title="Blacklist" placement="right">
            <BlockIcon color="primary" />
          </LightTooltip>
        </NavLink>
        <NavLink to="/tags">
          <LightTooltip title="Tags" placement="right">
            <BookmarkIcon color="primary" />
          </LightTooltip>
        </NavLink>
        <Button
          onClick={() => setHidden(false)}
          className={styles.tag_button}
        >
          <LightTooltip title="Add Domains" placement="right">
            <AddBoxIcon color="primary" />
          </LightTooltip>
        </Button>
      </nav>
    </>
  );
}

export default Navigation;
