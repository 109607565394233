/* selectAccountsActions */
export const SET_SELECTED_ACCOUNTS_SUCCESS = 'selectedAccountsActions/SET_SELECTED_ACCOUNTS_SUCCESS';

export const AUTH_SET_DATA_SUCCESS = 'authActions/AUTH_SET_DATA_SUCCESS';
export const AUTH_DISCARD_TOKEN = 'authActions/AUTH_DISCARD_TOKEN';

/* Locations actions */
export const GET_LOCATIONS_LIST_REQUEST = 'locationsActions/GET_LOCATIONS_LIST_REQUEST';
export const GET_LOCATIONS_LIST_SUCCESS = 'locationsActions/GET_LOCATIONS_LIST_SUCCESS';
export const GET_LOCATIONS_LIST_FAILURE = 'locationsActions/GET_LOCATIONS_LIST_FAILURE';
export const GET_LOCATIONS_DEMOGRAPHICS_REQUEST = 'locationsActions/GET_LOCATIONS_DEMOGRAPHICS_REQUEST';
export const GET_LOCATIONS_DEMOGRAPHICS_SUCCESS = 'locationsActions/GET_LOCATIONS_DEMOGRAPHICS_SUCCESS';
export const GET_LOCATIONS_DEMOGRAPHICS_FAILURE = 'locationsActions/GET_LOCATIONS_DEMOGRAPHICS_FAILURE';
export const GET_LOCATIONS_PROVIDERS_REQUEST = 'locationsActions/GET_LOCATIONS_PROVIDERS_REQUEST';
export const GET_LOCATIONS_PROVIDERS_SUCCESS = 'locationsActions/GET_LOCATIONS_PROVIDERS_SUCCESS';
export const GET_LOCATIONS_PROVIDERS_FAILURE = 'locationsActions/GET_LOCATIONS_PROVIDERS_FAILURE';
export const GET_LOCATIONS_METRICS_REQUEST = 'locationsActions/GET_LOCATIONS_METRICS_REQUEST';
export const GET_LOCATIONS_METRICS_SUCCESS = 'locationsActions/GET_LOCATIONS_METRICS_SUCCESS';
export const GET_LOCATIONS_METRICS_FAILURE = 'locationsActions/GET_LOCATIONS_METRICS_FAILURE';
export const UPDATE_LOCATIONS_METRICS_REQUEST = 'locationsActions/UPDATE_LOCATIONS_METRICS_REQUEST';
export const UPDATE_LOCATIONS_METRICS_SUCCESS = 'locationsActions/UPDATE_LOCATIONS_METRICS_SUCCESS';
export const UPDATE_LOCATIONS_METRICS_FAILURE = 'locationsActions/UPDATE_LOCATIONS_METRICS_FAILURE';
export const SET_LOCATIONS_FILTERS_SUCCESS = 'locationsActions/SET_LOCATIONS_FILTERS_SUCCESS';

export const SET_LOCATION_ID = 'locationsActions/SET_LOCATION_ID';

/* Domains actions */
export const RESET_DOMAINS_LIST_SUCCESS = 'domainsActions/RESET_DOMAINS_LIST_SUCCESS';
export const GET_DOMAINS_LIST_REQUEST = 'domainsActions/GET_DOMAINS_LIST_REQUEST';
export const GET_DOMAINS_LIST_SUCCESS = 'domainsActions/GET_DOMAINS_LIST_SUCCESS';
export const GET_DOMAINS_LIST_FAILURE = 'domainsActions/GET_DOMAINS_LIST_FAILURE';
export const GET_DOMAIN_REQUEST = 'domainsActions/GET_DOMAIN_REQUEST';
export const GET_DOMAIN_SUCCESS = 'domainsActions/GET_DOMAIN_SUCCESS';
export const GET_DOMAIN_FAILURE = 'domainsActions/GET_DOMAIN_FAILURE';
export const GET_DOMAIN_ACTIVITY_REQUEST = 'domainsActions/GET_DOMAIN_ACTIVITY_REQUEST';
export const GET_DOMAIN_ACTIVITY_SUCCESS = 'domainsActions/GET_DOMAIN_ACTIVITY_SUCCESS';
export const GET_DOMAIN_ACTIVITY_FAILURE = 'domainsActions/GET_DOMAIN_ACTIVITY_FAILURE';
export const GET_DOMAIN_TRAFFIC_REQUEST = 'domainsActions/GET_DOMAIN_TRAFFIC_REQUEST';
export const GET_DOMAIN_TRAFFIC_SUCCESS = 'domainsActions/GET_DOMAIN_TRAFFIC_SUCCESS';
export const GET_DOMAIN_TRAFFIC_FAILURE = 'domainsActions/GET_DOMAIN_TRAFFIC_FAILURE';
export const UPDATE_DOMAIN_TRAFFIC_REQUEST = 'domainsActions/UPDATE_DOMAIN_TRAFFIC_REQUEST';
export const UPDATE_DOMAIN_TRAFFIC_SUCCESS = 'domainsActions/UPDATE_DOMAIN_TRAFFIC_SUCCESS';
export const UPDATE_DOMAIN_TRAFFIC_FAILURE = 'domainsActions/UPDATE_DOMAIN_TRAFFIC_FAILURE';
export const GET_DOMAIN_COMPETITORS_REQUEST = 'domainsActions/GET_DOMAIN_COMPETITORS_REQU';
export const GET_DOMAIN_COMPETITORS_SUCCESS = 'domainsActions/GET_DOMAIN_COMPETITORS_SUCC';
export const GET_DOMAIN_COMPETITORS_FAILURE = 'domainsActions/GET_DOMAIN_COMPETITORS_FAILURE';
export const SET_DOMAINS_FILTERS_SUCCESS = 'domainsActions/SET_DOMAINS_FILTERS_SUCCESS';
export const SET_DOMAINS_CHIPS_SUCCESS = 'domainsActions/SET_DOMAINS_CHIPS_SUCCESS';
export const GET_KEY_PHRASE_SUCCESS = 'domainsActions/GET_KEY_PHRASE_SUCCESS';
export const GET_KEY_PHRASE_FAILURE = 'domainsActions/GET_KEY_PHRASE_FAILURE';
export const GET_KEY_PHRASE_REQUEST = 'domainsActions/GET_KEY_PHRASE_REQUEST';
export const UPDATE_KEY_PHRASE_REQUEST = 'domainsActions/UPDATE_KEY_PHRASE_REQUEST';
export const UPDATE_KEY_PHRASE_SUCCESS = 'domainsActions/UPDATE_KEY_PHRASE_REQUEST';
export const UPDATE_KEY_PHRASE_FAILURE = 'domainsActions/UPDATE_KEY_PHRASE_REQUEST';

/* Tags actions */
export const DELETE_TAG_REQUEST = 'tagsActions/DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'tagsActions/DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'tagsActions/DELETE_TAG_FAILURE';
export const DELETE_TAG_FROM_DOMAIN_REQUEST = 'tagsActions/DELETE_TAG_FROM_DOMAIN_REQUEST';
export const DELETE_TAG_FROM_DOMAIN_SUCCESS = 'tagsActions/DELETE_TAG_FROM_DOMAIN_SUCCESS';
export const DELETE_TAG_FROM_DOMAIN_FAILURE = 'tagsActions/DELETE_TAG_FROM_DOMAIN_FAILURE';
export const GET_TAGS_LIST_REQUEST = 'tagsActions/GET_TAGS_LIST_REQUEST';
export const GET_TAGS_LIST_SUCCESS = 'tagsActions/GET_TAGS_LIST_SUCCESS';
export const GET_TAGS_LIST_FAILURE = 'tagsActions/GET_TAGS_LIST_FAILURE';
export const GET_DOMAIN_TAGS_REQUEST = 'tagsActions/GET_DOMAIN_TAGS_REQUEST';
export const GET_DOMAIN_TAGS_SUCCESS = 'tagsActions/GET_DOMAIN_TAGS_SUCCESS';
export const GET_DOMAIN_TAGS_FAILURE = 'tagsActions/GET_DOMAIN_TAGS_FAILURE';
export const ADD_DOMAIN_TAGS_REQUEST = 'tagsActions/ADD_DOMAIN_TAGS_REQUEST';
export const ADD_DOMAIN_TAGS_SUCCESS = 'tagsActions/ADD_DOMAIN_TAGS_SUCCESS';
export const ADD_DOMAIN_TAGS_FAILURE = 'tagsActions/ADD_DOMAIN_TAGS_FAILURE';
export const ADD_TAG_REQUEST = 'tagsActions/ADD_TAG_REQUEST';
export const ADD_TAG_SUCCESS = 'tagsActions/ADD_TAG_SUCCESS';
export const ADD_TAG_FAILURE = 'tagsActions/ADD_TAG_FAILURE';
export const EDIT_TAG_REQUEST = 'tagsActions/EDIT_TAG_REQUEST';
export const EDIT_TAG_SUCCESS = 'tagsActions/EDIT_TAG_SUCCESS';
export const EDIT_TAG_FAILURE = 'tagsActions/EDIT_TAG_FAILURE';

/* BLACKLIST ACTIONS */
export const GET_BLACKLIST_LIST_REQUEST = 'blacklistActions/GET_BLACKLIST_LIST_REQUEST/';
export const GET_BLACKLIST_LIST_SUCCESS = 'blacklistActions/GET_BLACKLIST_LIST_SUCCESS/';
export const GET_BLACKLIST_LIST_FAILURE = 'blacklistActions/GET_BLACKLIST_LIST_FAILURE/';
export const REMOVE_FROM_BLACKLIST_LIST_REQUEST = 'blacklistActions/REMOVE_FROM_BLACKLIST_LIST_REQUEST/';
export const REMOVE_FROM_BLACKLIST_LIST_SUCCESS = 'blacklistActions/REMOVE_FROM_BLACKLIST_LIST_SUCCESS/';
export const REMOVE_FROM_BLACKLIST_LIST_FAILURE = 'blacklistActions/REMOVE_FROM_BLACKLIST_LIST_FAILURE/';
export const RESET_BLACKLIST_LIST = 'blacklistActions/RESET_BLACKLIST_LIST/';

export const SET_VISIBLE_COLUMNS_SUCCESS = 'visibleColumnsActions/SET_VISIBLE_COLUMNS_SUCCESS';
