import * as actionConstants from 'actions/constants';
import storage from 'redux-persist/lib/storage';

export const visibleColumnsPersistConfig = {
  key: 'visibleColumns',
  storage,
};

export default function visibleColumnsReducer(state = {
  status: '',
  errorMessage: '',
  data: [],
}, action) {
  switch (action.type) {
    case actionConstants.SET_VISIBLE_COLUMNS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: action.data,
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
