import * as redux from 'redux';
import { persistReducer } from 'redux-persist';

import auth from './authReducer';
import tags from './tagsReducer';
import blacklist from './blacklistReducer';

import selectedAccountReducer, { selectedAccountPersistConfig } from './selectedAccountsReducer';
import domainsReducer, { domainsPersistConfig } from './domainsReducer';
import locationsReducer, { locationsPersistConfig } from './locationsReducer';
import visibleColumnsReducer, { visibleColumnsPersistConfig } from './visibleColumnsReducer';

const selectedAccounts = persistReducer(selectedAccountPersistConfig, selectedAccountReducer);
const domains = persistReducer(domainsPersistConfig, domainsReducer);
const locations = persistReducer(locationsPersistConfig, locationsReducer);
const visibleColumns = persistReducer(visibleColumnsPersistConfig, visibleColumnsReducer);

const rootReducer = redux.combineReducers({
  selectedAccounts,
  locations,
  tags,
  domains,
  blacklist,
  auth,
  visibleColumns,
});

export default rootReducer;
