import isoFetch from 'isomorphic-fetch';
import { snakeCase } from 'lodash';

const defaultHeaders = {
  Accept: 'application/json, application/xml, text/plain, text/html, *.*, */*',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
};

function queryParams(params) {
  return Object.keys(params)
    .map(k => (params[k] ? `${encodeURIComponent(snakeCase(k))}=${encodeURIComponent(params[k])}` : ''))
    .filter(k => k && k !== null)
    .join('&');
}

export default (url, opts = {}) => {
  if (opts && opts.queryParams) {
    // eslint-disable-next-line no-param-reassign
    url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(opts.queryParams);
    delete opts.queryParams;
  }

  return isoFetch(
    url,
    Object.assign({
      headers: opts.headers
        ? Object.assign(opts.headers, defaultHeaders)
        : defaultHeaders,
    }, Object.assign({}, opts ? {
      ...opts,
      body: JSON.stringify(opts.body),
    } : {})),
  )
    .then((response) => {
      if (response.status >= 400) {
        return response.json()
          .then(data => Promise.reject(data));
      }
      return Promise.resolve(response.json());
    });
};
