import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs }) => crumbs.map(
  ({ name, path }, key) => (key + 1 === crumbs.length ? (
    <span
      key={String(key)}
    >
      {name}
    </span>
  ) : (
    <Link
      key={String(key)}
      to={path}
    >
      {name}
    </Link>
  )),
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Breadcrumbs;
