import {
  SET_LOCATION_ID,
  SET_LOCATIONS_FILTERS_SUCCESS,
  GET_LOCATIONS_LIST_REQUEST,
  GET_LOCATIONS_LIST_SUCCESS,
  GET_LOCATIONS_LIST_FAILURE,
  GET_LOCATIONS_DEMOGRAPHICS_REQUEST,
  GET_LOCATIONS_DEMOGRAPHICS_SUCCESS,
  GET_LOCATIONS_DEMOGRAPHICS_FAILURE,
  GET_LOCATIONS_METRICS_REQUEST,
  GET_LOCATIONS_METRICS_SUCCESS,
  GET_LOCATIONS_METRICS_FAILURE,
  UPDATE_LOCATIONS_METRICS_REQUEST,
  UPDATE_LOCATIONS_METRICS_SUCCESS,
  UPDATE_LOCATIONS_METRICS_FAILURE,
} from 'actions/constants';

import storage from 'redux-persist/lib/storage';

export const locationsPersistConfig = {
  key: 'locations',
  storage,
};

export default function locationsReducer(state = {
  status: '',
  errorMessage: '',
  data: {},
  location: {},
  location_id: {},
  filters: {},
  demographics: {},
  metrics: {},
},
action) {
  switch (action.type) {
    case SET_LOCATIONS_FILTERS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        filters: action.data,
      };

    case GET_LOCATIONS_LIST_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_LOCATIONS_LIST_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: action.data,
      };

    case SET_LOCATION_ID:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        location_id: action.data,
      };

    case GET_LOCATIONS_LIST_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        data: {},
      };

    case GET_LOCATIONS_DEMOGRAPHICS_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_LOCATIONS_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        demographics: action.data,
      };

    case GET_LOCATIONS_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        demographics: {},
      };

    case GET_LOCATIONS_METRICS_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_LOCATIONS_METRICS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        metrics: action.data,
      };

    case GET_LOCATIONS_METRICS_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        metrics: {},
      };

    case UPDATE_LOCATIONS_METRICS_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case UPDATE_LOCATIONS_METRICS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
      };

    case UPDATE_LOCATIONS_METRICS_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
