import React, { Suspense, lazy } from 'react';
import Fallback from 'components/Fallback';
import {
  Route,
} from 'react-router-dom';

const BlacklistList = lazy(() => import('components/BlacklistList'));

const Blacklist = () => (
  <div className="columns__container">
    <Suspense fallback={<Fallback title="Loading Blacklist" />}>
      <Route
        path="/blacklist/:domain?"
        component={BlacklistList}
      />
    </Suspense>
  </div>
);

export default Blacklist;
