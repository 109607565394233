import Locations from 'components/Locations';
import Blacklist from 'components/Blacklist';
import Domains from 'components/Domains';
import Tags from 'components/Tags';
import domainsFilters from 'services/domainsFilters';

const defaultFilters = domainsFilters.statusOpts.filter((filter) => filter.value === '302');

const routes = [{
  path: '/locations/:city?/:state?/:domain?',
  component: Locations,
  name: 'Locations',
},
{
  path: '/domains/:domain?',
  component: Domains,
  name: 'Domains',
},
{
  path: '/tags',
  component: Tags,
  name: 'Tags',
},
{
  path: '/302s/:domain?',
  component: Domains,
  name: '302s',
  defaultFilters,
},
{
  path: '/blacklist/:domain?',
  component: Blacklist,
  name: 'Blacklist',
},
];

export default routes;
