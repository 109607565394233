import React, { Suspense, lazy } from 'react';
import {
  Route, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import Fallback from 'components/Fallback';
import { PropTypes } from 'prop-types';
import styles from './core.module.scss';

const TagsList = lazy(() => import('components/TagsList'));
const DomainsList = lazy(() => import('components/DomainsList'));
const DomainsRightPanel = lazy(() => import('components/DomainsRightPanel'));

const Tags = () => (
  <Suspense fallback={<Fallback title="Loading Tags" />}>
    <div className="tags__container">
      <section className={`tags__left-column ${styles['tags__list-container']}`}>
        <Route
          path="/tags"
          render={() => <TagsList />}
        />
      </section>
      <section className="tags__center-column">
        <Route
          path="/tags/:domain?"
          render={() => <DomainsList />}
        />
      </section>
      <Route
        exact
        path="/tags/:domain"
        render={() => <DomainsRightPanel />}
      />
    </div>
  </Suspense>
);

Tags.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Tags),
);
