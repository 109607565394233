import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router, Redirect, Switch,
} from 'react-router-dom';

import styles from 'components/App/core.module.scss';
import { useAuth0 } from 'auth0/react-auth0-wrapper';
import history from 'utils/history';

import Fallback from 'components/Fallback';
import ErrorBoundary from 'components/ErrorBoundary';
import Navigation from 'components/Navigation';
import Header from 'components/Header';
import Loader from 'components/Loader';

import routes from 'routes';

const Error404 = lazy(() => import('components/Error404'));
const PrivateRoute = lazy(() => import('components/PrivateRoute/index'));

const App = () => {
  const { loading } = useAuth0();

  return loading ? <Loader /> : (
    <main className={styles.app__container}>
      <Router history={history}>
        <Header />
        <Navigation />
        <section className={styles.maincontents__container}>
          <ErrorBoundary>
            <Suspense fallback={<Fallback title="Loading…" />}>
              <Switch>
                <PrivateRoute
                  exact
                  path="/"
                  render={() => (
                    loading ? (
                      <Loader />
                    ) : (
                      <Redirect to="/domains" />
                    )
                  )}
                />
                {
                  routes.map((route, key) => (
                    <PrivateRoute
                      path={route.path}
                      key={String(key)}
                      defaultFilters={route.defaultFilters}
                      component={route.component}
                    />
                  ))
                }
                <PrivateRoute
                  component={Error404}
                />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </section>
      </Router>
    </main>
  );
};

export default App;
