import React from 'react';
import styles from './core.module.scss';

const Loader = () => (
  <div className={`${styles['lovingly-loading']} animated pulse infinite`}>
    <img src="https://res.cloudinary.com/ufn/image/upload/v1498485660/lovingly-logos/lovingly-notification-icon.png" alt="loading" />
  </div>
);

export default Loader;
