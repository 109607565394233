import {
  createStore,
  compose,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';

const composeEnhancer = ((typeof window !== 'undefined') && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const middleware = [thunk, logger];

const persistConfig = {
  key: 'root',
  whitelist: [],
  storage,
};

export function initializeSession() {}

export default function configureStore(initialState = {}) {
  return createStore(
    persistReducer(persistConfig, rootReducer),
    initialState,
    composeEnhancer(applyMiddleware(...middleware)),
  );
}
