import fetch from 'services/fetch';
import {
  uniqWith,
  isEqual,
} from 'lodash';

import * as constants from './constants';

const {
  REACT_APP_API_BASE_URL,
} = process.env;

export const setDomainsFilters = (data) => ({
  type: constants.SET_DOMAINS_FILTERS_SUCCESS,
  data,
});

export const setDomainsChips = (data) => ({
  type: constants.SET_DOMAINS_CHIPS_SUCCESS,
  data: uniqWith(data, isEqual),
});

/* Get List */

export const getDomainsListRequest = (url) => ({
  type: constants.GET_DOMAINS_LIST_REQUEST,
  url,
});

export const getDomainsListSuccess = (data) => {
  console.log('getDomainsListSuccess', data);
  return ({
    type: constants.GET_DOMAINS_LIST_SUCCESS,
    data,
  });
};

export const resetDomainsList = () => {
  console.log('resetDomainsList');
  return ({
    type: constants.RESET_DOMAINS_LIST_SUCCESS,
    data: {
      _page: {},
      domains: [],
    },
  });
};

export const getDomainsListFailure = (errorMessage) => ({
  type: constants.GET_DOMAINS_LIST_FAILURE,
  errorMessage,
});

export function getDomainsList(queryParams, saveToState = false) {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/domains/`;
    dispatch(getDomainsListRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;

    return fetch(requestUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      queryParams: {
        ...queryParams,
        sortBy: undefined,
        sort: queryParams.sortBy,
      },
    })
      .then((res) => {
        // Avoid making another action just to get the data and not save it to the store
        // This is so we can make a call and not affect anything else relying on the store data
        if (saveToState) {
          const domainsData = getState().domains.data.domains;

          return dispatch(getDomainsListSuccess({
            _page: res._page,
            domains: domainsData
              && domainsData.length
              ? domainsData.concat(res.domains) : res.domains,
          }));
        }
        return Promise.resolve(res);
      })
      .catch((error) => {
        if (saveToState) { dispatch(getDomainsListFailure(error)); }
        return Promise.reject(error);
      });
  };
}

/* Get Single Domain */

export const getDomainRequest = (url) => ({
  type: constants.GET_DOMAIN_REQUEST,
  url,
});

export const getDomainSuccess = (data) => ({
  type: constants.GET_DOMAIN_SUCCESS,
  data,
});

export const getDomainFailure = (errorMessage) => ({
  type: constants.GET_DOMAIN_FAILURE,
  errorMessage,
});

export function getDomain(domain) {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/domains/${domain}`;
    dispatch(getDomainRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;
    return fetch(requestUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => dispatch(getDomainSuccess(
        res.domains ? res.domains[0] : {},
      )))
      .catch((error) => {
        dispatch(getDomainFailure(error));
        return Promise.reject(error);
      });
  };
}

/* getActivity */

export const getDomainActivityRequest = (url) => ({
  type: constants.GET_DOMAIN_ACTIVITY_REQUEST,
  url,
});

export const getDomainActivitySuccess = (data) => ({
  type: constants.GET_DOMAIN_ACTIVITY_SUCCESS,
  data,
});

export const getDomainActivityFailure = (errorMessage) => ({
  type: constants.GET_DOMAIN_ACTIVITY_FAILURE,
  errorMessage,
});

export function getDomainActivity(domain) {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/domains/activity/${domain}`;
    dispatch(getDomainActivityRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;
    return fetch(requestUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => dispatch(getDomainActivitySuccess(
        res,
      )))
      .catch((error) => {
        dispatch(getDomainActivityFailure(error));
        return Promise.reject(error);
      });
  };
}

/* GET TRAFFIC */

export const getDomainTrafficRequest = (url) => ({
  type: constants.GET_DOMAIN_TRAFFIC_REQUEST,
  url,
});

export const getDomainTrafficSuccess = (data) => ({
  type: constants.GET_DOMAIN_TRAFFIC_SUCCESS,
  data,
});

export const getDomainTrafficFailure = (errorMessage) => ({
  type: constants.GET_DOMAIN_TRAFFIC_FAILURE,
  errorMessage,
});

export function getDomainTraffic(domain, rangeAmount, rangeType) {
  return async (dispatch, getState) => {
    let requestUrl = `${REACT_APP_API_BASE_URL}/domains/traffic/${domain}`;

    if (rangeAmount && rangeType) {
      const moment = (await import('moment')).default;
      const startDate = moment().subtract(rangeAmount, rangeType).format('YYYY-MM-DD');
      requestUrl += `?start_date=${startDate}`;
    }

    dispatch(getDomainTrafficRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;
    return fetch(requestUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => dispatch(getDomainTrafficSuccess(
        res,
      )))
      .catch((error) => {
        dispatch(getDomainTrafficFailure(error));
        return Promise.reject(error);
      });
  };
}

/* POST (UPDATE) TRAFFIC */

export const updateDomainTrafficRequest = (url) => ({
  type: constants.UPDATE_DOMAIN_TRAFFIC_REQUEST,
  url,
});

export const updateDomainTrafficSuccess = (data) => ({
  type: constants.UPDATE_DOMAIN_TRAFFIC_SUCCESS,
  data,
});

export const updateDomainTrafficFailure = (errorMessage) => ({
  type: constants.UPDATE_DOMAIN_TRAFFIC_FAILURE,
  errorMessage,
});

export function updateDomainTraffic(domain) {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/domains/traffic/${domain}`;

    dispatch(updateDomainTrafficRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;
    return fetch(requestUrl, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        domain,
      },
    })
      .then((res) => dispatch(updateDomainTrafficSuccess(
        res,
      )))
      .catch((error) => {
        dispatch(updateDomainTrafficFailure(error));
        return Promise.reject(error);
      });
  };
}

/* GET COMPETITORS */

export const getDomainCompetitorsRequest = (url) => ({
  type: constants.GET_DOMAIN_COMPETITORS_REQUEST,
  url,
});

export const getDomainCompetitorsSuccess = (data) => ({
  type: constants.GET_DOMAIN_COMPETITORS_SUCCESS,
  data,
});

export const getDomainCompetitorsFailure = (errorMessage) => ({
  type: constants.GET_DOMAIN_COMPETITORS_FAILURE,
  errorMessage,
});

export function getDomainCompetitors(domain) {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/domains/competitors/${domain}`;
    dispatch(getDomainCompetitorsRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;
    return fetch(requestUrl, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => dispatch(getDomainCompetitorsSuccess(
        res,
      )))
      .catch((error) => {
        dispatch(getDomainCompetitorsFailure(error));
        return Promise.reject(error);
      });
  };
}

/* Mark as contacted */

export const markDomainAsContactedRequest = (url) => ({
  type: constants.GET_DOMAIN_COMPETITORS_REQUEST,
  url,
});

export const markDomainAsContactedSuccess = (data) => ({
  type: constants.GET_DOMAIN_COMPETITORS_SUCCESS,
  data,
});

export const markDomainAsContactedFailure = (errorMessage) => ({
  type: constants.GET_DOMAIN_COMPETITORS_FAILURE,
  errorMessage,
});

export function markDomainAsContacted(domain) {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/domains/${domain}/contacted`;
    dispatch(markDomainAsContactedRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;
    return fetch(requestUrl, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => dispatch(markDomainAsContactedSuccess(
        res,
      )))
      .catch((error) => {
        dispatch(markDomainAsContactedFailure(error));
        return Promise.reject(error);
      });
  };
}

/* Edit domain */

export const editDomainRequest = (url) => ({
  type: constants.GET_DOMAIN_COMPETITORS_REQUEST,
  url,
});

export const editDomainSuccess = (data) => ({
  type: constants.GET_DOMAIN_COMPETITORS_SUCCESS,
  data,
});

export const editDomainFailure = (errorMessage) => ({
  type: constants.GET_DOMAIN_COMPETITORS_FAILURE,
  errorMessage,
});

export function editDomain(data) {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/domains/`;
    dispatch(editDomainRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;

    return fetch(requestUrl, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    })
      .then((res) => dispatch(editDomainSuccess(
        res,
      )))
      .catch((error) => {
        dispatch(editDomainFailure(error));
        return Promise.reject(error);
      });
  };
}

/* Move to Blacklist */
export const moveDomainsToBlacklistRequest = (url) => ({
  type: constants.GET_DOMAIN_COMPETITORS_REQUEST,
  url,
});

export const moveDomainsToBlacklistSuccess = (data) => ({
  type: constants.GET_DOMAIN_COMPETITORS_SUCCESS,
  data,
});

export const moveDomainsToBlacklistFailure = (errorMessage) => ({
  type: constants.GET_DOMAIN_COMPETITORS_FAILURE,
  errorMessage,
});

export function moveDomainsToBlacklist(domains) {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/blacklist/`;
    dispatch(moveDomainsToBlacklistRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;
    return fetch(requestUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'post',
      body: {
        domains,
      },
    })
      .then((res) => dispatch(moveDomainsToBlacklistSuccess(
        res,
      )))
      .catch((error) => {
        dispatch(moveDomainsToBlacklistFailure(error));
        return Promise.reject(error);
      });
  };
}

/* ============== */
/* get key phrase */

export const getKeyPhraseRequest = (url) => ({
  type: constants.GET_KEY_PHRASE_REQUEST,
  url,
});

export const getKeyPhraseSuccess = (data) => ({
  type: constants.GET_KEY_PHRASE_SUCCESS,
  data,
});

export const getKeyPhraseFailure = (errorMessage) => ({
  type: constants.GET_KEY_PHRASE_FAILURE,
  errorMessage,
});

export const getKeyPhrase = (domain) => (dispatch, getState) => {
  const requestUrl = `${REACT_APP_API_BASE_URL}/domains/phrase/${domain}`;
  dispatch(getKeyPhraseRequest(requestUrl));
  const {
    token,
  } = getState().auth.data;
  return fetch(requestUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => dispatch(getKeyPhraseSuccess(
      res,
    )))
    .catch((error) => {
      dispatch(getKeyPhraseFailure(error));
      return Promise.reject(error);
    });
};

export const updateKeyPhraseRequest = (url) => ({
  type: constants.UPDATE_KEY_PHRASE_REQUEST,
  url,
});

export const updateKeyPhraseSuccess = (data) => {
  console.log('phrasedata', data); return ({
    type: constants.UPDATE_KEY_PHRASE_SUCCESS,
    data,
  });
};

export const updateKeyPhraseFailure = (errorMessage) => ({
  type: constants.UPDATE_KEY_PHRASE_FAILURE,
  errorMessage,
});

export function updateKeyPhrase(domain = '') {
  return (dispatch, getState) => {
    const requestUrl = `${REACT_APP_API_BASE_URL}/domains/phrase/${domain}`;
    dispatch(updateKeyPhraseRequest(requestUrl));
    const {
      token,
    } = getState().auth.data;
    return fetch(requestUrl, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        domain,
      },
    })
      .then((res) => dispatch(updateKeyPhraseSuccess(
        res,
      )))
      .catch((error) => {
        dispatch(updateKeyPhraseFailure(error));
        return Promise.reject(error);
      });
  };
}
