import { SET_SELECTED_ACCOUNTS_SUCCESS } from 'actions/constants';
import storage from 'redux-persist/lib/storage';

export const selectedAccountPersistConfig = {
  key: 'selectedAccounts',
  storage,
};

export default function selectedAccountReducer(state = {
  status: '',
  errorMessage: '',
  data: [],
}, action) {
  switch (action.type) {
    case SET_SELECTED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: action.data,
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
