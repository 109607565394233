import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Muli", sans-serif',
    useNextVariants: true,
  },
  MuiButton: {
    root: {
      borderRadius: 4,
      border: 0,
      height: 36,
      fontWeight: 800,
      fontSize: 14,
      text: {
        color: '#FFF',
      },
    },
    contained: {
      '&$disabled': {
        backgroundColor: 'rgba(0, 51, 73, .12)',
        color: 'rgba(0, 51, 73, .42)',
      },
    },
  },
  MuiCheckbox: {
    root: {
      borderColor: 'rgba(178, 190, 203, .56)',
    },
    checked: {
      color: 'white',
      borderColor: '#A4A4DE',
      background: '#A4A4DE',
    },
  },
  wrapper: {
    fontSize: 18,
    fontWeight: 600,
  },
  palette: {
    primary: {
      main: '#A4A4DE',
    },
    secondary: {
      main: '#F5C3DE',
    },
  },
});

export default theme;
