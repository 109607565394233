import React from 'react';
import Cancel from '@material-ui/icons/Cancel';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { editDomain } from 'actions/domainsActions';
import { withStyles } from '@material-ui/core/styles';
import styles from './core.module.scss';

const muiStyles = {
  textField: {
    fontSize: '18px',
    color: '#616161',
    resize: 'none',
    borderRadius: '3px',
    background: '#fff',
    border: '1px solid #e1e1e1',
    width: '100%',
  },
  textContainer: {
    width: '100%',
  },
};

const regexTest = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

class BulkDomainsOverlay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newDomains: '',
      isError: false,
      apiMessage: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  addBulkDomains() {
    const { editDomain } = this.props;
    const { newDomains } = this.state;

    this.setState({
      isSubmitting: true,
      isError: false,
    });

    Promise.all(newDomains.match(regexTest).map(
      (domain) => editDomain({ domain_name: domain }),
    ))
      .then(() => this.setState({
        apiMessage: 'All domains were successfully added!',
      }))
      .catch(() => this.setState({
        apiMessage: 'There\'s been an error, please try again.',
        isError: true,
      }))
      .finally(() => this.setState({
        isSubmitting: false,
      }));
  }

  handleChange(event) {
    this.setState({ newDomains: event.target.value });
  }

  render() {
    const {
      onClose,
      isSearch,
      classes,
    } = this.props;

    const {
      newDomains, isError, apiMessage, isSubmitting,
    } = this.state;
    return (
      <div className="overlay">
        <div className={`overlay__content-container ${styles['bulk-domains__content-container']} ${styles['tags-add__container']}`}>
          <Cancel
            className="close-icon"
            onClick={onClose}
          />
          <div
            style={{ paddingLeft: '30px' }}
          >
            <h2 className={styles['bulk-domains__title']}>{isSearch ? 'Add Domains to Search' : 'Add Domains to Pearl'}</h2>
            <TextField
              id="outlined-textarea"
              className={classes.textContainer}
              InputProps={{
                className: classes.textField,
              }}
              label="Enter domains here…"
              placeholder="flowersbysteve.com,awesomeblossom.com"
              multiline
              margin="normal"
              rows={16}
              variant="outlined"
              value={newDomains}
              onChange={this.handleChange}
            />
            <div className={styles['bulk-domains__form-grid']}>
              <div>
                <p className={styles['bulk-domains__help-text']}>When adding multiple domains, separate each domain with a comma(,).</p>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles['bulk-domains__submit-button']}
                  onClick={() => (
                    isSearch ? onClose(newDomains.match(regexTest)) : this.addBulkDomains()
                  )}
                >
                  {isSubmitting ? 'ADDING…' : 'ADD DOMAINS'}
                </Button>
                <p className={`${styles['bulk-domains__api-message']} ${isError ? 'error' : ''}`}>{apiMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BulkDomainsOverlay.defaultProps = {
  isSearch: false,
};

BulkDomainsOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  editDomain: PropTypes.func.isRequired,
  isSearch: PropTypes.bool,
  classes: PropTypes.shape({
    textContainer: PropTypes.string,
    textField: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  editDomain,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(muiStyles)(
    BulkDomainsOverlay,
  ),
);
