import {
  GET_DOMAINS_LIST_REQUEST,
  SET_DOMAINS_FILTERS_SUCCESS,
  SET_DOMAINS_CHIPS_SUCCESS,
  GET_DOMAINS_LIST_SUCCESS,
  GET_DOMAINS_LIST_FAILURE,
  GET_DOMAIN_REQUEST,
  GET_DOMAIN_SUCCESS,
  GET_DOMAIN_FAILURE,
  GET_DOMAIN_ACTIVITY_REQUEST,
  GET_DOMAIN_ACTIVITY_SUCCESS,
  GET_DOMAIN_ACTIVITY_FAILURE,
  GET_DOMAIN_TRAFFIC_REQUEST,
  GET_DOMAIN_TRAFFIC_SUCCESS,
  GET_DOMAIN_TRAFFIC_FAILURE,
  GET_DOMAIN_COMPETITORS_REQUEST,
  GET_DOMAIN_COMPETITORS_SUCCESS,
  GET_DOMAIN_COMPETITORS_FAILURE,
  GET_KEY_PHRASE_REQUEST,
  GET_KEY_PHRASE_SUCCESS,
  GET_KEY_PHRASE_FAILURE,
  UPDATE_KEY_PHRASE_REQUEST,
  UPDATE_KEY_PHRASE_SUCCESS,
  UPDATE_KEY_PHRASE_FAILURE,
  UPDATE_DOMAIN_TRAFFIC_REQUEST,
  UPDATE_DOMAIN_TRAFFIC_SUCCESS,
  UPDATE_DOMAIN_TRAFFIC_FAILURE,
  RESET_DOMAINS_LIST_SUCCESS,
} from 'actions/constants';
import storage from 'redux-persist/lib/storage';

export const domainsPersistConfig = {
  key: 'domains',
  storage,
};

export default function domainsReducer(state = {
  status: '',
  errorMessage: '',
  data: {},
  domain: {},
  filters: {},
  chips: [],
  traffic: {},
  competitors: {},
  activity: {},
  phrases: {},
}, action) {
  switch (action.type) {
    case GET_DOMAINS_LIST_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case SET_DOMAINS_FILTERS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        filters: action.data,
      };

    case SET_DOMAINS_CHIPS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        chips: action.data,
      };

    case GET_DOMAINS_LIST_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: action.data,
      };

    case GET_DOMAINS_LIST_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        data: {},
      };

    case GET_DOMAIN_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case RESET_DOMAINS_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
      };

    case GET_DOMAIN_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        domain: action.data,
      };

    case GET_DOMAIN_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        domain: {},
      };

    case GET_DOMAIN_ACTIVITY_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_DOMAIN_ACTIVITY_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        activity: action.data,
      };

    case GET_DOMAIN_ACTIVITY_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        traffic: {},
      };

    case GET_DOMAIN_TRAFFIC_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_DOMAIN_TRAFFIC_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        traffic: action.data,
      };

    case GET_DOMAIN_TRAFFIC_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        traffic: {},
      };

    case GET_DOMAIN_COMPETITORS_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_DOMAIN_COMPETITORS_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        competitors: action.data,
      };


    case GET_DOMAIN_COMPETITORS_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        competitors: {},
      };

    case GET_KEY_PHRASE_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_KEY_PHRASE_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        phrases: action.data,
      };

    case GET_KEY_PHRASE_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        phrases: {},
      };
    case UPDATE_KEY_PHRASE_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };
    case UPDATE_KEY_PHRASE_SUCCESS:
      console.log('action', action);
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        phrases: action.data,
      };
    case UPDATE_KEY_PHRASE_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        phrases: {},
      };
    case UPDATE_DOMAIN_TRAFFIC_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };
    case UPDATE_DOMAIN_TRAFFIC_SUCCESS:
      return {
        ...state,
        status: 'SUCESS',
        errorMessage: '',
        traffic: action.data,
      };
    case UPDATE_DOMAIN_TRAFFIC_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        traffic: {},
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
