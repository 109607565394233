import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    margin: '30px auto',
    height: '65px',
    maxWidth: '600px',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
  },
});

export default function Fallback({ title }) {
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      variant="outlined"
    >
      <CardContent>
        <Typography
          className={classes.title}
          variant="h6"
          component="h6"
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}

Fallback.propTypes = {
  title: PropTypes.string.isRequired,
};
