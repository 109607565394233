import React from 'react';
import {
  Route,
  withRouter,
} from 'react-router-dom';
import routes from 'routes';
import { PropTypes } from 'prop-types';
import styles from './core.module.scss';
import Breadcrumbs from './breadcrumbs';

const Header = () => (
  <header className={styles.header__container}>
    <h1 className={`${styles.header__title} no-margin`}>
      {window.location.href.includes('lost') ? 'LOST' : 'PEARL'}
    </h1>
    <nav className={styles.breadcrumbs}>
      {routes.map(({ path }, key) => (
        <Route
          path={path}
          key={String(key)}
          render={(props) => {
            const crumbs = routes
              .filter(({ path }) => props.match.path.includes(path))
              .map(({ path, ...rest }) => ({
                path: () => path.slice(0, path.indexOf(':')),
                ...rest,
              }));

            if (Object.keys(props.match.params).length) {
              const replacedPath = Object.keys(props.match.params)
                .reduce((path, param) => path.replace(
                  `:${param}`, props.match.params[param],
                ).replace('?', ''), path).replace(/\?/gm, '');

              Object.keys(props.match.params)
                .map((param) => props.match.params[param]
                    && crumbs.push({
                      ...crumbs[0],
                      name: props.match.params[param],
                      path: replacedPath.slice(0,
                        replacedPath
                          .indexOf(props.match.params[param]) + props.match.params[param].length)
                         + (props.location.search || ''),
                    }));
            }

            return (
              <Breadcrumbs
                crumbs={crumbs}
                {...props}
              />
            );
          }}
        />
      ))}
    </nav>
  </header>
);

Header.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({}),
    path: PropTypes.string,
  }).isRequired,
};

export default withRouter(Header);
