import {
  RESET_BLACKLIST_LIST, GET_BLACKLIST_LIST_REQUEST, GET_BLACKLIST_LIST_SUCCESS, GET_BLACKLIST_LIST_FAILURE,
} from 'actions/constants';
import initialState from './initialState';

export default function blacklistReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BLACKLIST_LIST_REQUEST:
      return {
        ...state,
        status: 'BUSY',
      };

    case GET_BLACKLIST_LIST_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: action.data,
      };

    case RESET_BLACKLIST_LIST:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: action.data,
      };

    case GET_BLACKLIST_LIST_FAILURE:
      return {
        ...state,
        status: 'ERROR',
        errorMessage: action.errorMessage,
        data: {},
      };


    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
