import { AUTH_SET_DATA_SUCCESS, AUTH_DISCARD_TOKEN } from 'actions/constants';
import initialState from './initialState';

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    // saves the token into the state
    case AUTH_SET_DATA_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: action.data,
      };

    // discards the current token (logout)
    case AUTH_DISCARD_TOKEN:
      return {
        ...state,
        status: 'SUCCESS',
        errorMessage: '',
        data: {},
      };

    default:
      return state;
  }
}
