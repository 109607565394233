/* eslint-disable class-methods-use-this */
import React from 'react';
import Fallback from 'components/Fallback';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.group();
    console.error(error);
    console.error(errorInfo);
    console.groupEnd();
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <Box mx="auto">
        <Fallback title="Something went wrong." />
      </Box>
    ) : children;
  }
}
ErrorBoundary.defaultProps = {
  children: {},
};

ErrorBoundary.propTypes = {
  children: PropTypes.shape({}),
};

export default ErrorBoundary;
